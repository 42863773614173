class SurveyNameBuilder {

  run = () => {
    this.setInitialValues();
    this.newSurveyFormListener();
    this.new360MetryxFormListener();
  };

  newSurveyFormListener = () => {
    $('#survey-topic, #survey-time-frame, #survey_leader_name, #survey_leader_name_360x, #survey-time-frame-360x').on('change keyup input', function (e) {
      const topicTextValue = $('#survey-topic').val();
      let topicText = topicTextValue === '' ? '(Topics)' : topicTextValue;
      const timeFrameTextValue = $('#survey-time-frame').val();
      let timeFrameText = timeFrameTextValue === '' ? '(Time Period)' : timeFrameTextValue;
      const surveyNameValue = $('#survey_leader_name').val();
      let surveyName = surveyNameValue === '' ? '(Subject)' : surveyNameValue;
      let actionWordString = $('#survey-topic').val() === 'values' ? 'maintain' :
        ($('#survey-topic').val() === 'inclusion' || $('#survey-topic').val() === 'wellbeing') ? 'ensure' : 'achieve'

      $('.action').text(actionWordString);
      $('.topic_text').text(topicText);
      $('.time_period_text').text(timeFrameText);
      $('.name_placeholder').text(surveyName);
    })
  };

  new360MetryxFormListener = () => {
    $('#survey_leader_name_360x, #survey-time-frame-360x').on('change keyup input', function (e) {
      const timeFrameTextValue = $('#survey-time-frame-360x').val();
      let timeFrameText = timeFrameTextValue === '' ? '(Time Period)' : timeFrameTextValue;
      const surveyNameValue = $('#survey_leader_name_360x').val();
      let surveyName = surveyNameValue === '' ? '(Subject)' : surveyNameValue;

      $('.time_period_text').text(timeFrameText);
      $('.name_placeholder').text(surveyName);
    })
  };

  setInitialValues = () => {
    $('.action').text('achieve');
    $('#survey-topic').val('').trigger('change');
    $('.time_period_text').text('(Time Period)');
    $('.topic_text').text('(Topics)');
    $('.name_placeholder').text('(Subject)');
    $('.behavior').text('(Behavior)');
  };
}

export default SurveyNameBuilder;